import React from 'react';
import Layout from 'components/layout';
import FullPageMessage from 'components/fullPageMessage';

const NotFound = () => (
	<Layout>
		<FullPageMessage>¯\_(ツ)_/¯</FullPageMessage>
	</Layout>
);

export default NotFound;
