import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Row = styled.section`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

export const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 9rem;
	${MEDIA.MIN_TABLET`
		font-size: 22rem;
	`};

	div {
		display: flex;
	}

	.message {
		margin-left: auto;
		margin-right: auto;
		margin-top: 5rem;
		font-size: 5rem;
		${MEDIA.MIN_TABLET`
			font-size: 9rem;
	`};
	}
`;
