import React from 'react';
import { Row, Column } from './fullPageMessage.css';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const FullPageMessage = ({ children }) => {
	const text = children.split('');
	const message = '404 Not Found'.split('');
	return (
		<Row>
			<Column>
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ delay: 1.8, duration: 2 }}
				>
					{text.map((letter, i) => (
						<motion.span
							key={i}
							animate={{ y: [0, -10, 0] }}
							transition={{
								loop: Infinity,
								ease: 'easeInOut',
								duration: 1.39 + i * 0.02,
								delay: i * 0.06,
							}}
							className="inner"
						>
							{letter}
						</motion.span>
					))}
				</motion.div>
				<div className="message">
					{message.map((letter, i) => (
						<motion.span
							key={i}
							initial={{
								y: Math.random() * 600 - 300,
								x: Math.random() * 600 - 300,
								opacity: 0,
							}}
							animate={{ y: 0, x: 0, opacity: 1 }}
							transition={{
								duration: 0.3,
								delay: i * 0.08,
							}}
							className="inner"
						>
							{letter === ' ' ? ' ' : letter}
						</motion.span>
					))}
				</div>
			</Column>
		</Row>
	);
};

FullPageMessage.propTypes = {
	children: PropTypes.string.isRequired,
};

export default FullPageMessage;
